import React from 'react';
import '../../assets/scss/loader.scss';

export const GridSkeleton = ({
  showImage,
  className = 'col-sm-6 col-md-4 col-lg-4 col-6',
  height,
  columnSize,
  padding = 5,
  contentType,
}) => {
  const class_name =
    columnSize === 3
      ? `col-6 col-sm-4 col-lg-${columnSize} col-xl-${columnSize} col-md-4 `
      : className;
  return (
    <div className={class_name}>
      <div className="card items_cards">
        {showImage ? (
          <figure
            className="card-img-top"
            style={{
              height: contentType === 'featured' ? height + 50 : height,
            }}
          >
            <div
              className="loader-card-content"
              style={{
                height: contentType === 'featured' ? height + 50 : height,
                padding,
              }}
            />
          </figure>
        ) : (
          <></>
        )}
        {contentType !== 'featured' ? (
          <div className="card-body">
            <div
              className="loader-card-content"
              style={{ padding, margin: 5 }}
            />

            <div className="row">
              <div className="col-sm-7">
                <div
                  className="loader-card-content"
                  style={{ padding, margin: 5 }}
                />
              </div>
              <div className="col-sm-5 text-right">
                <div
                  className="loader-card-content"
                  style={{ padding, margin: 5 }}
                />
              </div>
            </div>

            <div className="seller-name">
              <div
                className="loader-card-content"
                style={{ padding, margin: 5 }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        {!showImage ? (
          <div className="loader-card-content" style={{ height: 40 }} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export const ListSkeleton = ({
  showImage,
  width = 223,
  height,
  padding = 12,
  contentType,
  margin,
}) => (
  <div style={{ margin }}>
    <div
      className="list-group-item mb-2"
      style={{
        paddingTop: padding,
        paddingBottom: padding,
        paddingLeft: 20,
        paddingRight: 20,
        height,
      }}
    >
      <div className="row">
        {showImage ? (
          <div className="col-sm-12 d-none d-sm-block">
            <div
              className="loader-card-content mb-2"
              style={{ height: height - 10 * padding, padding }}
            />
          </div>
        ) : (
          <></>
        )}
        <div className={showImage ? 'col-sm-10' : 'col-sm-12 '}>
          <div>
            <div
              className="loader-card-content mb-2"
              style={{ width, padding }}
            />
            {!showImage ? (
              <div
                className="loader-card-content mt-2"
                style={{ width, padding }}
              />
            ) : (
              <></>
            )}
          </div>
          <div>
            <div
              className="loader-card-content mb-2"
              style={{ width, padding }}
            />
            <div className="loader-card-content" style={{ width, padding }} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const SkeletonLoader = ({
  type,
  showImage,
  className,
  count = 1,
  columnSize,
  width,
  height,
  contentType,
}) => {
  const background = '#eee';
  const containerClass =
    'row columns is-multiline is-mobile products is-centered';

  const Component = type === 'grid' ? GridSkeleton : ListSkeleton;
  return (
    <div className={type === 'grid' ? containerClass : undefined}>
      {Array.from({ length: count }).map((_, i) => (
        <Component
          key={i}
          contentType={contentType}
          showImage={showImage}
          className={className}
          columnSize={columnSize}
          width={width}
          height={showImage ? height : 100}
          background={background}
        />
      ))}
    </div>
  );
};
