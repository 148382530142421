import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Item from '../productList/item';
import Slider from '../testimonials/slider';
import { API_ENDPOINT_FACET_ITEMS } from '../../../store/shared/services/apiEndpoints';
import apiService from '../../../store/shared/services/apiService';
import { ListSkeleton } from '../loader/SkeletonLoader';

const skelatonArray = Array.from(Array(8), (_, index) => (
  <ListSkeleton
    border="1px black solid"
    margin="15px"
    height={300}
    showImage
    padding={15}
    key={index}
  />
));

const RecentSoldItems = () => {
  const [listOfItems, setListOfItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const items = async () => {
    setLoading(true);
    const resp = await apiService.request('GET', API_ENDPOINT_FACET_ITEMS, {
      state: 'sold',
      page_size: 8,
      page: 1,
    });
    setListOfItems(resp.data);
    setLoading(false);
  };
  useEffect(() => {
    items();
  }, []);
  return (
    <div className="container mb-5 mt-3">
      <h3 className="display-5 text-center mb-3">Recently Sold</h3>
      <div className="mx-3">
        <Slider
          slidesToShow={4}
          slidesToScroll={4}
          items={
            !loading
              ? listOfItems.map((item) => (
                  <Item key={item._id} margin="15px" item={item} />
                ))
              : skelatonArray
          }
        />
      </div>
      <div className="d-flex mt-5 mb-2 justify-content-center">
        <Link className="btn btn-primary" to="/search?state=sold">
          See more
        </Link>
      </div>
    </div>
  );
};

export default RecentSoldItems;
