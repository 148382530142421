import React, { useEffect, useState } from 'react';

import LazyLoad from 'react-lazyload';

const PlaceholderImage = ({ width, height }) => (
  <div style={{ height }}>
    <img
      src={`data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`}
      alt=""
    />
  </div>
);

const ItemImage = ({ images, productName, height, width, display }) => {
  // if (images && images.length > 0) {
  //   const image = images[0];
  //   // const imageUrl = helper.getThumbnailUrl(image.url, themeSettings.listThumbnailWidth);
  //   const imageUrl = image.thumbnail;
  //   const alt = image.alt && image.alt.length > 0 ? image.alt : productName;

  //   return (
  //     <LazyLoad
  //       placeholder={<PlaceholderImage height={height} width={width} />}
  //       height={height}
  //       style={{ height }}
  //     >
  //       <img
  //         className="product-img-thumbnail img-thumbnail"
  //         src={imageUrl}
  //         alt={alt}
  //         title={alt}
  //         style={{ height }}
  //       />
  //     </LazyLoad>
  //   );
  // }

  const [image, setImage] = useState(null);

  useEffect(() => {
    setImage(images[0]);
  }, []);

  return image ? (
    <div style={{ height, backgroundColor: 'white' }}>
      <LazyLoad
        placeholder={<PlaceholderImage height={height} />}
        height={height}
      >
        <span
          className="badge badge-danger"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            padding: '5px 15px',
            display,
          }}
        >
          Sold
        </span>
        <img
          className="product-img-thumbnail img-thumbnail"
          src={image.thumbnail}
          alt={image.alt && image.alt.length > 0 ? image.alt : productName}
          title={image.alt && image.alt.length > 0 ? image.alt : productName}
        />
      </LazyLoad>
    </div>
  ) : (
    <div style={{ height }} className="small-image-placeholder" />
  );

  // return <div style={{ height }} className="small-image-placeholder" />;
};

export default ItemImage;
