import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { isServer } from '../../store/shared/redux/store';

const CustomNavLink = (props) => {
	const navigateTo = () => {
		if (props.forceRefresh) {
			props.history.push(props.to);
			if (!isServer) {
				window.location.reload(true);
			}
		}
	};
	return props.forceRefresh ? (
		<a href={props.to}>{props.children}</a>
	) : (
			<Link to={navigateTo}>{props.children}</Link>
		);
};

CustomNavLink.propTypes = {
	to: PropTypes.string.isRequired,
	forceRefresh: PropTypes.bool,
};

export default withRouter(CustomNavLink);
