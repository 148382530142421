import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Testimonial = ({ comment, username }) => {
  const [open, toggleOpen] = useState(false);

  return (
    <>
      {/* <p style={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}>
        {open
          ? comment
          : comment.slice(0, 110).length < 110
          ? comment.slice(0, 110)
          : comment.slice(0, 110) + '...'}
        {open ? '' : ''}
      </p>
      {// console.log(showBtn.length)

      comment.length > 111 ? (
        <button
          type='button'
          onClick={() => toggleOpen(!open)}
          className='btn btn-link'>
          {open ? 'Show Less' : 'Show More'}
        </button>
      ) : (
        ''
      )}
      {/* <button
        type='button'
        onClick={() => toggleOpen(!open)}
        className='btn btn-link'
      >
        {open ? 'Show Less' : 'Show More'}
      </button> */}
      <div>
        <div
          className="card card-body m-2 d-flex align-content-between product-card"
          style={{ minHeight: 225 }}
        >
          <blockquote>
            <p style={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}>
              {open
                ? comment
                : comment.slice(0, 180).length < 180
                ? comment.slice(0, 180)
                : `${comment.slice(0, 180)}...`}
              {open ? '' : ''}
            </p>
            {
              // console.log(showBtn.length)

              comment.length > 180 ? (
                <button
                  type="button"
                  onClick={() => toggleOpen(!open)}
                  className="btn btn-link"
                  style={{ fontSize: 15 }}
                >
                  {open ? 'Show Less' : 'Show More'}
                </button>
              ) : (
                ''
              )
            }
          </blockquote>
          <footer className="blockquote-footer mt-auto">
            <Link to={`/account/${username}`}>{username}</Link>
          </footer>
        </div>
      </div>
    </>
  );
};
export default Testimonial;
