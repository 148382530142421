import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import apiService from '../../../store/shared/services/apiService';
import { itemsFavoritesEndpoint } from '../../../store/shared/services/apiEndpoints';
import { openModal } from '../../../store/shared/redux/actions/modalAction';
import LoginRegister from '../loginRegister';
// import { connect } from 'react-redux'

class FavoriteButton extends React.Component {
  state = {
    favorite: null,
    favoriters_count: null,
  };

  componentDidMount() {
    this.setState({
      favorite: this.props.item.favorite,
      favoriters_count: this.props.item.favoriters_count,
    });
  }

  toggleFavorite = () => {
    const { user, openModal } = this.props;
    if (!user || !user.isUserLoggedIn) {
      openModal({
        type: 'custom',
        title: '',
        size: 'small',
        content: <LoginRegister />,
        onClose: () => {
          const { user } = this.props;
          if (user && user.isUserLoggedIn) {
            this.sendToggleRequest();
          }
        },
      });
    } else {
      this.sendToggleRequest();
    }
  };

  sendToggleRequest = () => {
    const { user } = this.props;
    if (user.user_id !== this.props.item.seller_id) {
      const { favorite } = this.state;
      apiService
        .request(
          favorite ? 'DELETE' : 'POST',
          itemsFavoritesEndpoint(this.props.item._id)
        )
        .then((resp) => {
          this.setState({
            favorite: resp.data.favorite,
            favoriters_count: resp.data.favoriters_count,
          });
        });
    }
  };

  render() {
    const { favorite, favoriters_count } = this.state;
    return (
      <>
        <button
          className="btn btn-link p-0"
          type="button"
          style={{ color: this.props.isList ? 'inherit' : 'primary' }}
          onClick={this.toggleFavorite}
        >
          <FontAwesomeIcon
            icon={favorite ? 'heart' : ['far', 'heart']}
            style={{
              cursor: 'pointer',
              // color: 'invert(var(--theme-base-font-color))',
            }}
          />
          {this.props.isList
            ? favoriters_count > 0
              ? ` ${favoriters_count}`
              : ' '
            : favorite
            ? ' Favorited'
            : ' Save to Favorites'}
        </button>
      </>
    );
  }
}
// ItemFavorite.propType = {
//   itemId: PropTypes.string.isRequired,
//   favorite: PropTypes.boolean.isRequired,
//   favoriters_count: PropTypes.number.isRequired
// };
const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps, { openModal })(FavoriteButton);
