import React from 'react';
import Slider from './slider';
import Testimonial from './testimonial';

const testimonials = [
  {
    userAvatar: '',
    username: 'ali',
    comment:
      "I've sold and bought many computer parts on this website, it is hands down one of the easiest places to get rid of unwanted hardware or to get new stuff. The low fees compared to every other online marketplace are a big plus as a seller and no taxes are also amazing as a buyer. All products have both buyer and seller protection as well so I'm not worried about getting scammed. Overall it is a great site specific to computer hardware selling.",
  },
  {
    username: 'hellfire19981',
    userAvatar: '',
    comment:
      "I am genuinely pleased with the customer service and the site itself feels nicely laid out. I do like listing my own products on here rather than *large competing e-commerce company's website for several reasons. The first reason is having much lower fees. the second reason is definitely the customer service. the third is the more efficient payout times. Trust me, if you want to list PC products, do it here. If you're buying, this is also not a bad way to go.",
  },
  {
    username: 'j.t.',
    userAvatar: '',
    comment:
      'Going into this considering it was a semi new site was a little skeptical but it was a very nice experience and made very well. Everything was super easy, straight forward and the discord was great for help. I would 10/10 recommend this to anyone.',
  },
  {
    userAvatar: '',
    username: 'mpitogo',
    comment:
      'So far pcswaps.com is a very convenient alternative to eBay.  The process is pretty straightforward and easy to follow.',
  },
  {
    username: 'mantis',
    userAvatar: '',
    comment:
      'Hi, I just wanted to say thank you for having such a great site. I recently sold my motherboard and CPU I was no longer using and it sold rather quickly. I cant wait to see what else gets put on the site as you guys grow. Keep up the good work',
  },
  {
    username: 'convex',
    userAvatar: '',
    comment:
      'Amazing best website to get used parts especially since you have buyer protection and the buying process was a breeze ',
  },
];
const Hypetestimonials = () => (
  <>
    <h3 className="display-5 text-center mb-3">Testimonials</h3>
    <Slider
      slidesToShow={3}
      // 				items={testimonials.map((testimonial, index) => (
      // 					<div key={testimonial.username}>
      // 							<div className='card card-body m-1 d-flex align-content-between' style={{height:215}}>
      // 								<blockquote>
      // 									<p style={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}>{testimonial.open
      //           ? testimonial.comment
      //           : testimonial.comment.slice(0, 110).length < 110
      //           ? testimonial.comment.slice(0, 110)
      //           : testimonial.comment.slice(0, 110) + '...'}
      //         {testimonial.open ? '' : ''}</p>
      // 				{// console.log(showBtn.length)

      // testimonial.comment.length > 111 ? (
      // 	<button
      // 		type='button'
      // 		onClick={this.toggleOpen.bind(this, index)}
      // 		className='btn btn-link'>
      // 		{testimonial.open ? 'Show Less' : 'Show More'}
      // 	</button>
      // ) : (
      // 	''
      // )}
      // 								</blockquote>
      // 								<div className="mt-auto">
      // 									<footer className='blockquote-footer'>
      // 										<Link to={`/account/${testimonial.username}`}>
      // 											{testimonial.username}
      // 										</Link>
      // 									</footer>
      // 								</div>
      // 							</div>
      // 					</div>
      // 				))}
      items={testimonials.map((obj) => (
        <Testimonial
          comment={obj.comment}
          username={obj.username}
          key={obj.username}
        />
      ))}
    />
  </>
);

export default Hypetestimonials;
