/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
// import ItemTags from './itemTags';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import ItemImage from './itemImage';
import ItemPrice from './itemPrice';
import { StateContext } from '../../../store/shared/context';
import apiService from '../../../store/shared/services/apiService';
import { API_ENDPOINT_PRODUCT } from '../../../store/shared/services/apiEndpoints';
import FavoriteButton from './favoriteButton';
import { showRenewButton } from '../../lib/helper';
import CustomNavLink from '../CustomNavLink';

const showTwoLines = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '2' /* number of lines to show */,
  lineHeight: 'X' /* fallback */,
  maxHeight: 'X * N' /* fallback */,
};

const Item = ({ item, settings, history, reloadList, margin, display }) => {
  const imageHeight = 'auto';

  const placeholderHeight = 200;
  const placeholderWidth = 200;
  const {
    user: { user_id },
  } = useContext(StateContext);
  const onDelete = () => {
    apiService
      .request('DELETE', `${API_ENDPOINT_PRODUCT}/${item._id}`)
      .then((resp) => {
        if (reloadList) {
          reloadList();
        }
      });
  };
  const onRenew = () => {
    apiService
      .request('PUT', `${API_ENDPOINT_PRODUCT}/${item._id}/renew`)
      .then((resp) => {
        if (reloadList) {
          reloadList();
        }
      });
  };

  return (
    <div className="product-card card items_cards" style={{ margin, flex: '0 0 100%'}}>
      <div style={{ position: 'relative' }}>
        <CustomNavLink
          style={{ position: 'absolute', top: 0, right: 0 }}
          to={`/product/${item._id}`}
          forceRefresh
        >
          <figure className="card-img-top" style={{ height: imageHeight, maxHeight: placeholderHeight }}>
            <ItemImage
              display={item.state === 'sold' ? 'inline' : 'none'}
              images={item.images}
              productName={item.name}
              height={imageHeight}
              width={placeholderWidth}
            />
          </figure>
        </CustomNavLink>
        <div
          className="row justify-content-between"
          style={{
            fontWeight: 'bold',
            position: 'absolute',
            paddingBottom: 10,
            alignItems: 'flex-end',
            width: '100%',
            bottom: 15,
            left: 15,
            height: 64,
            color: 'white',
            background:
              'linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%)',
          }}
        >
          <div className="col-auto">
            <ItemPrice product={item} settings={settings} />
          </div>
          <div className="col-auto text-right">
            <FavoriteButton isList item={item} />
          </div>
        </div>
      </div>
      <div className="card-body" style={{ paddingTop: 0, height: 85 }}>
        <CustomNavLink to={`/product/${item._id}`} forceRefresh>
          <div
            style={{ ...showTwoLines, fontWeight: 'bold' }}
            className="card-product-name product-name"
          >
            {item.name}
          </div>
        </CustomNavLink>
        {item.seller && (
          <div className="card-seller-name seller-name">
            <NavLink
              style={{ textDecoration: 'none', fontWeight: 'lighter' }}
              to={`/account/${item.seller.username}`}
            >
              {item.seller.username}
            </NavLink>
          </div>
        )}
      </div>
      {item.seller_id === user_id && item.state !== 'sold' ? (
        <div className="card-footer item-actions  accountItemRow">
          <small
            className="text-muted"
            onClick={() => history.push(`/items/${item._id}`)}
          >
            Edit{' '}
          </small>
          {item.seller_id === user_id &&
            item.state !== 'sold' &&
            item.state !== 'removed' && (
              <small className="text-muted" onClick={onDelete}>
                | Delete{' '}
              </small>
            )}
          {showRenewButton(item) && (
            <small className="text-muted" onClick={onRenew}>
              | Renew
            </small>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToSettings = (state) => ({ settings: state.app.settings });
export default withRouter(connect(mapStateToSettings)(Item));
